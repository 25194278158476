import { DateString, PhaseString, Timestamp, TimestampString, Uuid } from "./global";

export enum PRODUCTION_STATUS {
	ACTIVE = "active",
	ARCHIVED = "archived",
}

export enum REQUEST_STATUS {
	PENDING = "pending",
	ENQUEUED = "enqueued",
	SOLVED = "solved",
	COMPLETED = "completed",
	FAILED = "failed",
}

/** https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-30235 */
export interface Production {
	id?: number
	uuid?: Uuid
	name: string

	status: PRODUCTION_STATUS
	startDate: DateString
	endDate: DateString

	/** RELATIONS */
	farmId: number
	requests: Request[]

	/** EXTRA */
	// è una riga collassata?
	_cll?: boolean
}



//#region REQUEST

/** https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-30555 */
export interface Request {
	uuid?: Uuid
	name: string
	/** se è in attesa di SOLUTIONs */
	status: REQUEST_STATUS

	constraints?: RequestConstraints

	/** read only object filled when root request is generated by OS **/
	requestPayload?: any

	/** RELATIONS */
	/** id del PRODUCT da utilizzare */
	products?: { productUuid: Uuid, percentage: number }[]
	/** il PARENT-PRODUCTION che lo contiene */
	productionUuid?: Uuid
	/** le SOLUTION relative a questa REQUEST */
	solutions?: Solution[]
	/** quando è stata creata la REQUEST */
	createdAt?: TimestampString
}

export interface ProductPerc {
	productUuid: Uuid,
	percentage: number,
}
 

/** https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-30555?block=block-650003ca-023d-43ca-ac46-d3af523a0090 */
export interface RequestConstraints {
	/** starting date for the request **/
	startingFrom?: DateString
	/** 
	number of days used as a "perdiod". standard is a week, but can be longer. 
	metrics and period constraints use this number as a referennce
	range 7 - 20
	**/
	harvestPeriod?: number
	/** 
	tell to try solutions with the number of harvest per period in the array.
	for each number, a different solution is tried out.
	it can be showed to the user with a 2-points slider
	*/
	numberHarvestPerPeriod?: number[]
	/** 
	how many days that have to be filled out with solutions. 
	Can be also showed with an "ending date" calendar component
	*/
	timeFrame?: number
	/** man-hour 0 - 200:max number of tasks weight in days 
	 * moltiplicare/dividere per 60 dato che il BE riceve in minuti
	*/
	maxDailyWorkload?: number
	/** 
	set of days of the week to impose an harvest (0 - 6). 
	it enforce harvest happens only in that days 
	*/
	harvestOnlyOn?: number[]
	
	/* dayoffs_on_week_days
    An array of integers that represents the dayoffs by day number on the week.
    
    Example:
      dayoffs_on_week_days: [0,5]; - means that the 0 - Monday, 5 - Saturday
    */
	daysOffOnWeekDays?: number[]
	
	/* dayoffs_on_dates
    The list of specific dayoff dates. 
    
    The dates should be >= starting_from and <= (starting_from + time_frame)
    starting_from... dates ...(starting_from + time_frame)
    */
	daysOffOnDates?: DateString[]

	/** 
	how many layers to group for each harvest (1 - 200).
	used to limit the size of the cyles between a min and max values 
	*/
	layersPerHarvest?: { min: number, max: number }
	/** decide if try to allow sexpand or shrink of some phases to better fit cycles */
	enableElasticPhases?: boolean
	/** 
	enable additional tryis sligly adjsut the number of layers used. can find more 
	solutions but it takes more time 
	*/
	enableMultiGroupsTries?: boolean
	/** 
	??? limit the number of time used by the solver to fit cycles in a batch. the more     
	the time ,the more optmizied is the solutions proposed 
	*/
	timeoutPerIteration?: number

	layers?: RequestLayer[]
}

export interface RequestLayer {
	layerId: number
	layerNumber: number
	growUnitId: number
	phase: PhaseString
	disabled: boolean
	
	filtered?: boolean
	column?: number
	row?: number
}

//#endregion



//#region SOLUTION

/** https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33735 */
export interface Solution {
	uuid: Uuid
	metrics: SolutionMetrics

	/** RELATIONS */
	requestUuid: Uuid
	solutionCrops: SolutionCrop[]
	/** i simil-CYCLEs della SOLUTION */
	solutionCycles?: SolutionCycle[]
	/** i simil-TASKs della SOLUTION */
	solutionTaskGroups?: SolutionTaskGroup[]
	/** all response fields from root */
	payload?: any

	/** mi serve per comodità */
	_parent?: Request
}

export interface SolutionMetrics {
	score: number
	yieldPerWeek: number
	periodYield: number
	layersHarvestedPerWeek: number
	layersPerHarvest: number
}

/** https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33735?block=block-f1ef4480-6b75-48df-915c-81bb19186862 */
export interface SolutionCrop {
	uuid: Uuid
	metrics: SolutionCropMetrics

	/** RELATIONS */
	solutionUuid: Uuid
	cropUuid: Uuid
}

export interface SolutionCropMetrics {
	score: number
	yieldPerWeek: number
	periodYield: number
	cropOnTotalYield: number
	yieldWeekMaxHarvests: number
	yieldZeroMeter: number
}

export interface SolutionCycle {
	uuid: Uuid
	cropUuid: Uuid
	cycleYield: number
	phases: SolutionCyclePhase[]
}

export interface SolutionCyclePhase {
	name: string
	// layers ID (non number) are used to identify the layer
	layers: number[]
	busyFrom: DateString
	busyTo: DateString
}

export interface SolutionTaskGroup {
	uuid: Uuid,
	occupationWeight: number,
	description: string,
	dueDate: DateString,
	templateGroupUuid: Uuid,
	cycleUuid: Uuid,
	solutionUuid: Uuid,
}

//#endregion