import { Box } from "@mui/material";
import React, { FunctionComponent, useMemo } from "react";

import { useStore } from "@priolo/jon";
import TasksList from "pages/farms/tasks/list/TasksList";
import dialogSo from "stores/cycle/editDialog";
import { getTaskFiltered, groupTasksByDate } from "stores/task/utils";
import taskSo from "stores/task"
import querySo from "stores/route/query"
import { URL_PAR } from 'stores/route/utils/types'
import PlaceholderCmp, { ICON_TYPE } from "components/controls/PlaceholderCmp";

const CycleTasksTab: FunctionComponent = () => {

	// STORE
	const dialogSa = useStore(dialogSo)
	const taskSa = useStore(taskSo)
	const querySa = useStore(querySo)
	

	// HOOKs

	// RENDER
	const groups = useMemo(() => {
		const [cycleSel] = querySo.getSearchUrl([URL_PAR.CYCLE_SEL])
		return groupTasksByDate(getTaskFiltered(taskSo.state.all, null, null, cycleSel))
	}, [taskSa.all, querySa.queryUrl])

	if ( groups.length == 0 ) {
		return <PlaceholderCmp
			title="THERE ARE NO TASKS" 
			subtitle="this cycle has no tasks" 
			iconType={ICON_TYPE.VOID}
		/>
	}

	return (
		<Box sx={{ display: "flex", flexDirection: "column"}}>
			<TasksList
				groups={groups}
			/>
		</Box>
	)
}

export default CycleTasksTab

