import { PhaseString } from "types/global"
import { getPhasesDictionary, PHASE_SLUG } from "./utils"

/**
 * dato un array di LAYERS
 * restituisce un dictionary con i nomi della PHASES come key
 * e un array di layer per ogni KEY
 * mantiene l'ordine definito in "stores/phase/utils.js"
 * @example
 * { "phase1": [{<layer>},{<layer>},...], "phase2": [{<layer>},{<layer>},...], ...}
 */
export function groupLayersByPhases<T extends LayerCommon>(layers: T[] ): { [phase: string]: T[] } {
	// raggruppo i LAYERs per PHASE
	const groupsLayers = layers.reduce((acc, layer) => {
		const phase = layer.phase
		if (layer.disabled || !phase || phase == PHASE_SLUG.PRE_CYCLE || phase == PHASE_SLUG.POST_CYCLE) return acc
		if (acc[phase] == null) acc[phase] = []
		acc[phase].push(layer)
		return acc
	}, getPhasesDictionary())
	return Object.keys(groupsLayers).reduce((acc, key) => {
		const value = groupsLayers[key]
		if (!value || value.length == 0) return acc
		acc[key] = value
		return acc
	}, {})
}

interface LayerCommon {
	phase?: PhaseString, 
	disabled?:boolean,
	layerNumber?: number,
}

/**
 * Da un DICTIONARY di Layer per PHASE rivavo un array con una specie di map
 * @param phases 
 * @param callback 
 * @returns 
 */
export function ungroupLayerByPhases(phases, callback) {
	return Object.keys(phases).reduce((acc, phase) => {
		const layers = phases[phase]
		if (callback) {
			return acc.concat(layers.map(layer => callback(phase, layer)))
		} else {
			return acc.concat(layers)
		}
	}, [])
}