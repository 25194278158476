import { Box, Typography } from "@mui/material";
import PageIcon from "components/layout/menu/PageIcon";
import { FunctionComponent } from "react";
import { MenuItem } from "stores/mainMenu/utils";
import palette from "styles/palette";


interface Props {
	item: MenuItem
	isRoot?: boolean
}

const SelectItem: FunctionComponent<Props> = ({
	item,
	isRoot,
}) => {

	// STORE

	// HOOKs

	// RENDER
	const cssIcon = isRoot ? {} : { width: "18px", height: "18px" }
	cssIcon["fill"] = palette.text.primary 
	const variant = isRoot ? "h5" : "subtitle1"
	const opacity = isRoot ? 1 : 0.5

	return (
		<Box sx={{ display: "flex", alignItems: "center", opacity }}>

			<PageIcon pageId={item.id} sx={cssIcon} />

			<Typography variant={variant} sx={{ m: "2px 7px 0px 5px" }}>
				{item.title}
			</Typography>
		</Box>
	)
}

export default SelectItem
