import { Box, Divider, Grid } from '@mui/material';
import Paragraph from 'components/controls/form/Paragraph'
import { useTranslation } from "react-i18next"
import dayjs from 'dayjs'
import CycleLayer from 'components/controls/CycleLayer'

import { useStore } from '@priolo/jon'
import cycleSo from 'stores/cycle'
import { FunctionComponent } from 'react';
import React from 'react';
import { getLayers, getOverlapError } from 'stores/cycle/utils/layer';
import { Crop } from 'types/Crop';
import { GrowUnit } from 'types/GrowUnit';

interface Props {
	growUnit?:GrowUnit,
	crop?: Crop,
}
/**
 * Visualizza i LAYER presenti nella PHASE "" (la phase BATCH) della FARM
 */
const CycleBatchLayersShow: FunctionComponent<Props> = ({
	growUnit,
	crop,
}) => {

	// STORE
	const cycleSa = useStore(cycleSo)
	//const { getLayers, getOverlapError } = cycleSo

	// HOOKs
	const { t } = useTranslation()

	// RENDER
	// const haveError = (layerNumber) => getOverlapError(
	// 	dayjs(cycleSa.select.seedingDate).format("YYYYMMDD"),
	// 	layerNumber,
	// 	verifyOverlap
	// )

	// RENDER
	if ( !growUnit || !crop ) return null
	const layers = getLayers(growUnit, crop)
	if (!layers || layers.length == 0) return null

	return (<>
		<Divider />
		<Box mt={2} />
		<Paragraph title={t("pag.cycle.selector.layers")}>
			<Grid container sx={cssRow}>

				{layers.map((layer) => (
					<CycleLayer key={layer.layerNumber}
						layer={layer}
						//error={haveError(layer.layerNumber)}
					/>
				))}

			</Grid>
		</Paragraph>
		<Divider />
	</>)
}

export default CycleBatchLayersShow

const cssRow = {
	margin: "5px 0px",
}
