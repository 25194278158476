
import { Box, Divider, Grid, Typography } from "@mui/material";
import Paragraph from 'components/controls/form/Paragraph';

import { useTranslation } from "react-i18next";

import { useStore } from "@priolo/jon";
import React, { FunctionComponent } from "react";
import dialogSo from "stores/cycle/newDialog";
import { getGrowUnitLayerName, getLayerByNumber } from "stores/growUnit/utils/layer";
import { groupLayersByPhases } from "stores/phase/layers";
import { PHASE_SLUG, getPhaseLabel } from "stores/phase/utils";
import { Crop } from "types/Crop";
import { GrowUnit } from "types/GrowUnit";
import { Layer } from "types/Layer";
import { PhaseString } from "types/global";
import { ChipLayer } from "./ChipLayer";


interface Props {
	growUnit?: GrowUnit
	crop?: Crop
	layerOvelap?: Layer[]
}
/**
 * Permette di selezionare i layer da utilizzare nel NUOVO CYCLE
 * i layer sono presi dalla FARM tramite le PHASEs del CROP
 */
const CycleLayersSelector: FunctionComponent<Props> = ({
	growUnit,
	crop,
	layerOvelap,
}) => {

	// STORE
	const dialogSa = useStore(dialogSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLEs
	// selezione dei LAYERS per una PHASE
	const handleLayerClick = (phase: string, layerNumber: number) => {
		const select = !dialogSo.getSelected({ phase, layerNumber })
		dialogSo.setSelected({ phase, layerNumber, select })
	}

	// RENDER
	if (!growUnit || !crop) return null

	// prelevo tutte le PHASES name del CROP evitando PRE e POST cycle
	const cropPhases = crop?.phases?.map(phaseObj => phaseObj.phase)
		.filter(phase => phase != PHASE_SLUG.PRE_CYCLE && phase != PHASE_SLUG.POST_CYCLE)
	if (!cropPhases || cropPhases.length == 0) return null

	const layersGroup = groupLayersByPhases(growUnit.desiredLayersState.layers)

	const getRatio = (phase: string) => crop.rearrangementSchema.find(r => r.phase == phase)?.ratio ?? ""
	const getNameLayer = (layerNumber: number) => getGrowUnitLayerName(growUnit, getLayerByNumber(growUnit, layerNumber))
	const isSelected = (phase: PhaseString, layerNumber: number) => dialogSo.getSelected({ phase, layerNumber })
	const isOverlap = (layerNumber:number) => layerOvelap.some( layer => layer.layerNumber == layerNumber )

	return (<>
		<Box mt={2} />
		<Grid container item alignItems="center">
			<Grid item sm={4}>
				<Typography variant="caption">{t("pag.cycle.selector.phases-ratio")}</Typography>
			</Grid>
			<Grid item sm={8}>
				<Typography variant="caption">{t("pag.cycle.selector.layers")}</Typography>
			</Grid>
		</Grid>
		<Box mt={1} />
		<Divider />
		<Box mt={4} />

		{cropPhases.map(phase => (
			<Paragraph key={phase}
				title={<>
					<span style={{ textTransform: "uppercase", fontWeight: "900" }}>{getPhaseLabel(phase)}</span>
					<span style={{ fontSize: "smaller", marginLeft: "5px" }}>{getRatio(phase)}</span>
				</>}
			>
				<Box key={phase} sx={cssRow}>
					{layersGroup[phase]?.map((layer) => (
						<ChipLayer key={layer.layerNumber}
							label={getNameLayer(layer.layerNumber)}
							selected={isSelected(phase, layer.layerNumber)}
							disabled={layer.disabled}
							overlap={isOverlap(layer.layerNumber)}
							onClick={(e) => handleLayerClick(phase, layer.layerNumber)}
						/>
					))}
				</Box>
			</Paragraph>
		))}

	</>)
}

export default CycleLayersSelector


const cssRow = {
	display: "flex",
	margin: "5px 0px",
	flexWrap: "wrap",
	gap: "6px",
}

