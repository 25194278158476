import { Box, Divider, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import dialogSo from "stores/cycle/editDialog";
import CycleLayersShow2 from "./CycleLayersShow2";
import Paragraph from "components/controls/form/Paragraph";


const CycleConsumptionTab: FunctionComponent = () => {

    // STORE
    const dialogSa = useStore(dialogSo)

    // HOOKs

    // RENDER
    if (!dialogSa.cycle?.powerConsumption) return null
    const total = dialogSa.cycle?.powerConsumption.consumedKwh?.toFixed(2)

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Paragraph title="TOTAL">
                <Typography variant="h6">{total} <span style={{ fontSize: "12px", opacity: 0.8 }}>kwh</span></Typography>
            </Paragraph>
            <CycleLayersShow2 cycle={dialogSa.cycle} />
        </Box>
    )
}

export default CycleConsumptionTab
