import React, { FunctionComponent, useMemo, useState } from "react";
import SelectorChipsBase from "../base/SelectorChipsBase";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MultiSelectorDialogBase from "../base/MultiSelectorDialogBase";

import farmSo from "stores/farm";
import { useStore } from "@priolo/jon";
import { Farm } from "types/Farm";
import FarmIcon from "images/menu/farm"



interface Props {
	values: number[]
	onChange:(newIds:number[])=>void

	placeholder:string
	disabled:boolean
	helperText:string
	sx:any
}

const SelectorFarms:FunctionComponent<Partial<Props>> = ({
	values = [],
	onChange,

	placeholder,
	disabled,
	helperText,
	sx,
}) => {

	// STORE
	const farmSa = useStore(farmSo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [farmsSelect, setFarmsSelect] = useState<Farm[]>([])
	const [textFilter, setTextFilter] = useState("")

	useEffect(() => {
		farmSo.fetchAllIfVoid()
	}, [])

	useEffect(() => {
		setFarmsSelect(farmSo.getByIds(values))
	}, [farmSa.all, values])



	// HANDLER
	const handleSelectorChipsClick = () => {
		if (disabled) return
		setIsOpen(true)
	}
	const handleFarmsSelectChange = (newFarms:Farm[]) => {
		onChange(newFarms.map(farm => farm.id))
	}
	const handleClose = (isOk) => {
		setIsOpen(false)
		if (isOk) handleFarmsSelectChange(farmsSelect)
	}


	// RENDER
	const farms = useMemo(() => farmSo.getFilteredExt(textFilter), [farmSa.all, textFilter])
	return (<>
		<SelectorChipsBase sx={sx}
			values={farmsSelect}
			placeholder={"No farms selected"}
			disabled={disabled}
			helperText={helperText}

			onChange={handleFarmsSelectChange}
			onClick={handleSelectorChipsClick}

			fnTextFromItem={item => item.name}
		/>
		<MultiSelectorDialogBase
			icon={<FarmIcon fill="#cfcfcf" width="24px" height="24px"/>}
			width="auto"
			title={t("selector.farm.title")}
			textPlaceholder="Search the farms..."

			items={farms}
			itemsSelect={farmsSelect}
			textValue={textFilter}

			isOpen={isOpen}
			onClose={handleClose}
			onChangeItemsSelect={farms => setFarmsSelect(farms)}
			onChangeTextValue={txt => setTextFilter(txt)}
			fnTextFromItem={(farm:Farm) => farm.name}
			fnSecondaryFromItem={farm => farmSo.getDetails(farm)}
			fnIdFromItem={farm => farm.id}
		/>
	</>)
}

export default SelectorFarms

