import { createStore, StoreCore } from "@priolo/jon";
import cyclesApi from "api/cycles";
import tasksApi from "api/tasks";
import i18n from "i18next";
import cycleSo from "stores/cycle";
import farmSo from "stores/farm";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";
import { Cycle } from "types/Cycle";
import { EditCycle } from "./utils/edit";
import { EDIT_TYPE } from "types/Entity";
import { TAGS } from "components/planner/types";
import { SESSION_TYPE } from "stores/draft/utils";
import growUnitSo from "stores/growUnit";
import { CropLot } from "types/CropLot";
import { croplot } from "pages/farms/bi/mock_data";



// used when dialog closed
let resolveClose = null

const setup = {

	state: {
		/** indica se la dialog è aperta o no */
		isOpen: false,
		/** indice del tab selezionato */
		tab: 0,
		/** il CYCLE che si sta modificando */
		cycle: <Cycle>null,
		/** indica che il bottone SYNC è disabilitato */
		syncDisabled: false,
	},

	getters: {
	},

	actions: {
		openReadOnly: async (cycleUuid: string, store?: CycleDialogStore) => {

		},

		/** apre la dialog con il cycle da mostrare */
		open: async (cycle: Cycle, store?: CycleDialogStore) => {
			if (!cycle /*|| cycle._edit?.type == EDIT_TYPE.DELETED || !!cycle.rejected*/) return
			const farmId = farmSo.state.select.id
			store.setTab(0)
			store.setSyncDisabled(false)

			// clone da editare
			let cycleDetail = EditCycle.current(cycle, true, true)
			// se il CYCLE NON stato modificato allora carico il dettaglio principalmente per avere il consumo
			if (!cycle._edit && cycle.id && EditCycle.getExtra(cycle, TAGS.SESSION) != SESSION_TYPE.PREVIEW) {
				const { data: cycleLoaded } = await cyclesApi.get(farmId, cycle.id)
				cycleDetail = { ...cycleLoaded, ...cycleDetail }
			}
			// lo setto da editare
			store.setCycle(cycleDetail)
			// apro la dialog
			store.setIsOpen(true)
			return new Promise((resolve, reject) => resolveClose = resolve)
		},
		/** chiude la dialog  */
		close: async (type: CYCLE_EDIT_TYPE, store?: CycleDialogStore) => {
			if (resolveClose) resolveClose(type)
			resolveClose = null
			store.setIsOpen(false)
		},
		/** Permette di settare lo YIELD per questo CYCLE */
		setYieldCropLot: (crop: Partial<CropLot>, store?: CycleDialogStore) => {
			const cropLot = store.state.cycle?.cropLots?.find(cropLot => cropLot.id == crop.id)
			if (!croplot) return
			if (crop.yield != null) cropLot.yield = crop.yield
			if (crop.unitsYield != null) cropLot.unitsYield = crop.unitsYield
			store.setCycle({ ...store.state.cycle })
		},
		/** -> BE tutti i crop_lots del CYCLE */
		harvestCropLots: async (_: void, store?: CycleDialogStore) => {
			const promises = store.state.cycle?.cropLots
				?.filter(cropLot => cropLot.yield && !isNaN(cropLot.yield))
				?.map(cropLot => cyclesApi.harvestCropLot(cropLot.lotCode, cropLot.yield, cropLot.unitsYield));
			await Promise.all(promises)

			// feedback and close
			dialogSo.dialogOpen({ type: DIALOG_TYPE.INFO, modal: false, text: i18n.t("snackbar.cycle.harvested") })
			store.close(CYCLE_EDIT_TYPE.HARVEST)
		},
		/** sincronizza tutti i TASKS di un CYCLE */
		taskSync: async (_: void, store?: CycleDialogStore) => {
			const cycleUuid = store.state.cycle?.cycleUuid
			const growUnitId = store.state.cycle?.growUnitId
			const growUnit = growUnitSo.getByID(growUnitId)
			if (!growUnit || !cycleUuid) return
			await tasksApi.sync(growUnit.farmId, cycleUuid)
			store.setSyncDisabled(true)
			dialogSo.dialogOpen({ type: DIALOG_TYPE.INFO, modal: false, text: i18n.t("snackbar.task.sync") })
		},

	},

	mutators: {
		setIsOpen: (isOpen: boolean) => ({ isOpen }),
		setTab: (tab: number) => ({ tab }),
		setCycle: (cycle: Cycle) => ({ cycle }),
		setSyncDisabled: (syncDisabled: boolean) => ({ syncDisabled }),
	},
}


export type CycleDialogState = typeof setup.state
export type CycleDialogGetters = typeof setup.getters
export type CycleDialogActions = typeof setup.actions
export type CycleDialogMutators = typeof setup.mutators
export interface CycleDialogStore extends StoreCore<CycleDialogState>, CycleDialogGetters, CycleDialogActions, CycleDialogMutators {
	state: CycleDialogState
}
const store = createStore(setup) as CycleDialogStore
export default store

export enum CYCLE_EDIT_TYPE {
	CLOSE,
	REJECT,
	HARVEST,
	ERROR,
}