import { AssignmentReturned as TaskGroupIcon } from '@mui/icons-material';
import { useStore } from '@priolo/jon';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import taskSo from "stores/taskTemplateGroup";
import { TTGFiltered } from 'stores/taskTemplateGroup/utils';
import { TaskTemplateGroup } from 'types/TaskTemplate';
import SelectorDialogBase from '../base/SelectorDialogBase';
import WrapControl, { ICON_VARIANT, WrapControlProps } from '../base/WrapControl';
import SelectorFarms from './SelectorFarms';



interface Props extends WrapControlProps {
	/** id del TEMPLATE TASK */
	value: number
	/** quando viene selezionato un TEMPLATE TASK */
	onChange: (ttg: TaskTemplateGroup) => void
}

const SelectorTaskTemplateGroup: FunctionComponent<Partial<Props>> = ({
	value,
	onChange,
	...props
}) => {

	// STORE
	const taskSa = useStore(taskSo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [textFilter, setTextFilter] = useState("")
	const [farmsFilter, setFarmsFilter] = useState<number[]>([])

	useEffect(() => {
		taskSo.fetchAllIfVoid()
	}, [])

	// HANDLER
	const handleClick = () => {
		if (props.disabled) return
		setIsOpen(true)
	}
	const handleClose = (ttg?: TaskTemplateGroup) => {
		setIsOpen(false)
		if (!ttg) return
		onChange?.(ttg)
	}
	const handleTextFilterChange = (txt: string) => {
		setTextFilter(txt)
	}

	// RENDER
	const taskTemplateGroups = useMemo(() => TTGFiltered(taskSa.all, textFilter, farmsFilter), [textFilter, taskSa.all, farmsFilter])
	const taskTemplateGroup: TaskTemplateGroup = useMemo(() => taskSo.getById(value), [value])

	return (<>

		<WrapControl
			variantIcon={ICON_VARIANT.PAGE}
			onClick={handleClick}
			placeholder="Click to select a Template"
			{...props}
		>
			{taskTemplateGroup?.name}
		</WrapControl>

		<SelectorDialogBase
			icon={<TaskGroupIcon />}
			title={t("selector.task-g-t")}

			items={taskTemplateGroups}
			textValue={textFilter}
			idSelect={value}

			isOpen={isOpen}
			onClose={handleClose}
			onChangeTextValue={handleTextFilterChange}
			fnTextFromItem={(ttg: TaskTemplateGroup) => ttg.name}
			fnIdFromItem={(ttg: TaskTemplateGroup) => ttg.id}

			renderFilterColumn={<SelectorFarms
				values={farmsFilter}
				onChange={farmsIds => setFarmsFilter(farmsIds)}
			/>}
		/>
	</>)
}

export default SelectorTaskTemplateGroup



