import { Box, Slider } from "@mui/material";
import { useStore } from "@priolo/jon";
import Paragraph from 'components/controls/form/Paragraph';
import DateRangePicker from "components/controls/select/DateRangePicker";
import SelectorCycle2 from "components/controls/select/SelectorCycle2";
import SelectorGrowUnit from "components/controls/select/SelectorGrowUnit";
import SelectorTaskRelated from "components/controls/select/SelectorTaskRelated";
import SelectorTaskTemplateGroup from "components/controls/select/SelectorTaskTemplateGroup";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import querySo from 'stores/route/query';
import { URL_PAR } from 'stores/route/utils/types';
import dialogSo from "stores/task/taskNewDialog";
import { RELATED_TO } from "types/Task";
import { TaskTemplateGroup } from "types/TaskTemplate";
import { DateRange } from "types/global";
import { dateRangeToUrl, urlToDateRange } from "utils";
import TaskTemplateList from "./TasksTemplateList";



interface Props {
	sx?: any
}
const TaskDetailTab: FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const dialogSa = useStore(dialogSo)
	const querySa = useStore(querySo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleChangeDateRange = (dateRange: DateRange) => {
		querySo.setSearchUrl([URL_PAR.DATE_SEL, dateRangeToUrl(dateRange)])
	}
	const handleTaskTempChange = (newTaskTemp: TaskTemplateGroup) => {
		if (!newTaskTemp) {
			dialogSo.setTaskTemplateGroupId(null)
			dialogSo.setTasks([])
			return
		}
		const groupId = newTaskTemp.id
		const tasks = newTaskTemp && newTaskTemp.taskTemplates ? [...newTaskTemp.taskTemplates] : []
		dialogSo.setTaskTemplateGroupId(groupId)
		dialogSo.setTasks(tasks)
	}
	const handleRelatedChange = (value: RELATED_TO) => {
		dialogSo.setRelatedTo(value)
	}

	// RENDER
	const dateRangeSelUrl = useMemo(
		() => querySo.getSearchUrl(URL_PAR.DATE_SEL) as string,
		[querySa.queryUrl]
	)
	const dateRangeSel = urlToDateRange(dateRangeSelUrl)
	return (
		<Box sx={[{ display: "flex", flexDirection: "column" }, sx]}>

			<Paragraph title={t("dialog.task.new.detail.date-range")} flex={25}>
				<DateRangePicker optionOneDay
					range={dateRangeSel}
					onChange={handleChangeDateRange}
				/>
			</Paragraph>

			<Paragraph title={t("dialog.task.new.detail.date-step", { day: dialogSa.dateStep })} flex={25}>
				<Slider
					min={1} step={1} max={15}
					marks
					value={dialogSa.dateStep}
					onChange={(_, dateStep) => dialogSo.setDateStep(dateStep as number)}
				/>
			</Paragraph>

			<Paragraph title={"Related to"} flex={25} sxChildren={{ gap: "5px" }}>
				<SelectorTaskRelated sx={{ flex: 1 }}
					value={dialogSa.relatedTo}
					onChange={handleRelatedChange}
				/>
				{dialogSa.relatedTo == RELATED_TO.CYCLE && (
					<SelectorCycle2 sx={{ flex: 3 }}
						value={dialogSa.cycleUuid}
						onChange={cycle => dialogSo.setCycleUuid(cycle?.cycleUuid)}
					/>
				)}
				{dialogSa.relatedTo == RELATED_TO.GROW_UNIT && (
					<SelectorGrowUnit sx={{ flex: 3 }}
						growUnitId={dialogSa.growUnitId}
						onChange={growUnit => dialogSo.setGrowUnitId(growUnit?.id)}
					/>

				)}
			</Paragraph>

			<Paragraph title={t("dialog.task.new.detail.groups")} flex={25}>
				<SelectorTaskTemplateGroup sx={{ flex: 1 }}
					value={dialogSa.taskTemplateGroupId}
					onChange={handleTaskTempChange}
				/>
			</Paragraph>

			<Paragraph title={t("dialog.task.new.detail.templates")} flex={25} isLast
				sx={{ flex: 1, alignItems: "stretch" }}
				sxChildren={{ bgcolor: "common.black", padding: "10px", borderRadius: "10px", ml: "6px" }}
			>
				<TaskTemplateList sx={{ flex: 1 }} />
			</Paragraph>

		</Box>
	)
}

export default TaskDetailTab

