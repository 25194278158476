import { PAGE_ID } from "stores/route/utils/types"
import { MenuItem } from "./utils"
import i18n from "i18next"
import { COMPONENT_TYPE } from "types/Service"



/**
 * sottosezione FARM -> COMPONENTS
 */
const ITEMS_GROWUNIT: MenuItem[] = [
	{ id: PAGE_ID.GROWUNIT_TOWERS, title: i18n.t("menu.farms.growUnits.layers"), group: COMPONENT_TYPE.GROWUNIT, },
	{ id: PAGE_ID.GROWUNIT_RECIPE, title: i18n.t("menu.farms.growUnits.recipe"), group: COMPONENT_TYPE.GROWUNIT, },
	{ id: PAGE_ID.GROWUNIT_HOME, title: i18n.t("menu.home.label"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	{ id: PAGE_ID.GROWUNIT_MAIN, title: i18n.t("menu.main.label"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	{ id: PAGE_ID.GROWUNIT_LAYERS, title: i18n.t("menu.farms.gu.layers"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	{ id: PAGE_ID.GROWUNIT_FERTI, title: i18n.t("menu.farms.gu.actions"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	{ id: PAGE_ID.GROWUNIT_ADJ, title: i18n.t("menu.farms.gu.adj"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	{ id: PAGE_ID.GROWUNIT_LIGHTING, title: i18n.t("menu.farms.gu.lighting"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	{ id: PAGE_ID.GROWUNIT_LIGHTING_PS, title: i18n.t("menu.farms.gu.lighting_ps"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	{ id: PAGE_ID.GROWUNIT_IRRIGATION, title: i18n.t("menu.farms.gu.irrigation"), group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },

	{ id: PAGE_ID.COMPONENT_HVAC_GROWROOM, title: i18n.t("menu.farms.gu.hvac"), group: COMPONENT_TYPE.HVAC, onlyNats: true },
	{ id: PAGE_ID.COMPONENT_GERMINATION, title: i18n.t("menu.farms.gu.germinator"), group: COMPONENT_TYPE.GERMINATOR, onlyNats: true },
]

/**
 * macrosezione FARM
 */
const ITEMS_FARM: MenuItem[] = [
	{ title: "HOME", id: PAGE_ID.FARM_MAP, onlyDebug: true, onlyNats: true, onlyMapConfig: true },
	{ title: i18n.t("menu.farms.growUnits.label"), id: PAGE_ID.FARM_GROWUNITS, children: ITEMS_GROWUNIT },
	{ title: i18n.t("menu.farms.planner"), id: PAGE_ID.FARM_PLANNER },
	{ title: i18n.t("menu.farms.productions"), id: PAGE_ID.FARM_AI_PLANNING, onlyDebug: true },
	{ title: i18n.t("menu.farms.tasks"), id: PAGE_ID.FARM_TASKS },
	{ title: i18n.t("menu.farms.cycles"), id: PAGE_ID.FARM_CYCLES },
	{ title: i18n.t("menu.farms.alerts"), id: PAGE_ID.FARM_ALERTS },
	{ title: "YIELDS", id: PAGE_ID.FARM_BI, onlyDebug: true },
	{ title: "POWERS", id: PAGE_ID.COMPONENT_POWER_MONITOR, onlyNats: true },
	// { title: "menu.farms.charts", id: PAGE_ID.FARM_CHARTS },
	// { title: "menu.farms.consoles", id: PAGE_ID.FARM_CONSOLES },
	{ title: i18n.t("menu.farms.technicalDashboards"), id: PAGE_ID.FARM_TECHNICAL_DASHBOARDS },
	{ title: i18n.t("menu.farms.businessDashboards"), id: PAGE_ID.FARM_BUSINESS_DASHBOARDS },
]

/**
 * macrosezione LIBRARY
 */
const ITEMS_LIBRARY: MenuItem[] = [
	// { id: PAGE_ID.FARMS, title: i18n.t("menu.farms.label"), children: ITEMS_FARM },
	{ id: PAGE_ID.RECIPES, title: i18n.t("menu.recipes"), subIds: [PAGE_ID.RECIPES_DETAIL] },
	{ id: PAGE_ID.CROPS, title: i18n.t("menu.crops"), subIds: [PAGE_ID.CROPS_DETAIL] },
	{ id: PAGE_ID.PRODUCTS, title: i18n.t("menu.products"), subIds: [PAGE_ID.PRODUCTS_DETAIL] },
	// { id: PAGE_ID.ALARMS, title: i18n.t("menu.alarms"), onlyAdmin: true, subIds: [PAGE_ID.ALARMS_DETAIL] },
	// { id: PAGE_ID.ROOT, title: i18n.t("menu.root") },
	// { id: PAGE_ID.ZW, title: i18n.t("menu.zw") },
]

/**
 * macrosezione LIBRARY
 */
const ITEMS_SETTINGS: MenuItem[] = [
	{ id: PAGE_ID.ALARMS, title: i18n.t("menu.alarms"), onlyAdmin: true, subIds: [PAGE_ID.ALARMS_DETAIL] },
]

/**
 * ROOT del MAIN-MENU
 */
export const ITEMS_MAIN: MenuItem[] = [
	{ id: PAGE_ID.LIBRARY, title: "LIBRARY", children: ITEMS_LIBRARY },
	{ id: PAGE_ID.FARMS, title: i18n.t("menu.farms.label"), children: ITEMS_FARM },
	{ id: PAGE_ID.SETTINGS, title: "SETTINGS", children: ITEMS_SETTINGS },
]