import { Box, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import PageIcon from "components/layout/menu/PageIcon";
import React, { FunctionComponent, useMemo } from "react";
import farmSo from "stores/farm";
import growUnitSo from "stores/growUnit";
import { getGrowUnitName } from "stores/growUnit/utils";
import { MenuItem } from "stores/mainMenu/utils";
import { PAGE_ID } from "stores/route/utils/types";
import palette from "styles/palette";
import ParentMenu from "./ParentMenu";



const isPLC = import.meta.env.VITE_TARGET == "plc"

interface Props {
	item: MenuItem,
	onClickIcon?: () => void
}

const ParentItem: FunctionComponent<Props> = ({
	item,
	onClickIcon,
}) => {

	// STORE
	const farmSa = useStore(farmSo)
	const growUnitSa = useStore(growUnitSo)

	// HOOKs

	// ricava i dati da visualizzare
	const title = useMemo(() => {
		if (isPLC) {
			if (item.id == PAGE_ID.FARM_GROWUNITS && !!farmSa.componentSelect) return farmSa.componentSelect.name
		} else {
			if (item.id == PAGE_ID.FARMS) return farmSa.select?.name
			if (item.id == PAGE_ID.FARM_GROWUNITS) return getGrowUnitName(growUnitSa.select)
		}
		return item.title
	}, [farmSa, growUnitSa, item.id])

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	// HANDLERS
	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}
	const handleCloseMenu = () => {
		setAnchorEl(null);
	}

	// RENDER
	const open = Boolean(anchorEl)
	const cssLabel = {
		m: "2px 7px 0px 5px",
		cursor: "pointer", //menuItems.length > 0 ? "pointer" : "default"
	}
	return <>
		<Box sx={{ display: "flex", alignItems: "center", fill: palette.text.primary }}>
			<PageIcon pageId={item.id} onClick={onClickIcon} sx={{ cursor: "pointer" }} />
			<Typography variant="h5" sx={cssLabel}
				onClick={handleOpenMenu}
			>
				{title}
			</Typography>
		</Box>

		<ParentMenu
			anchorEl={anchorEl}
			open={open}
			onClose={handleCloseMenu}
			item={item} 
		/>
	</>
}

export default ParentItem
