import { Box } from "@mui/material"
import React, { FunctionComponent } from "react"
import theme from "styles/theme"



interface ChipLayerProps {
	label?: string
	disabled?: boolean
	selected?: boolean
	overlap?: boolean
	onClick?: (e:React.MouseEvent) => void	
}

export const ChipLayer: FunctionComponent<ChipLayerProps> = ({
	label,
	disabled,
	selected,
	overlap,
	onClick,
}) => {
	const interactive = !disabled && !!onClick
	return <Box 
		sx={[cssChip, disabled && cssDisabled, selected && cssSelected, overlap && cssOverlap, interactive && cssInteractive]}
		onClick={interactive && onClick}
	>
		<Box sx={cssLabel}>
			{label}
		</Box>
	</Box>
}

const cssChip = {
	padding: "2px 7px 0px 7px",
	display: "flex",
	alignItems: "center",
	flexDirection: "column",

	borderRadius: "4px",
	border: "2px solid",
}

const cssSelected = {
	bgcolor: theme.palette.secondary.main,
	color: theme.palette.secondary.contrastText,
	borderColor: theme.palette.secondary.main,
}

const cssDisabled = {
	bgcolor: "text.disabled"
}

const cssInteractive = {
	cursor: "pointer"
}

const cssOverlap = {
	color: theme.palette.error.main,
	borderColor: theme.palette.error.main,
}

const cssLabel = {
	fontSize: "14px",
	fontWeight: 500,
}
