import { Box } from "@mui/material"
import { useStore } from "@priolo/jon"
import { FunctionComponent, useMemo } from "react"
import { useHistory } from "react-router-dom"
import draftSo from "stores/draft"
import deviceSo, { DEVICE_TYPE } from "stores/layout/device"
import drawerSo from "stores/mainMenu/drawer"
import sectionSo from "stores/mainMenu/sections"
import { MenuItem } from "stores/mainMenu/utils"
import { getUrlByPageId } from "stores/route/utils/pagesId"
import theme from "styles/theme"
import SectionItem from "./Sectiontem"
import natsSo, { NATS_STATUS } from "stores/nats"



interface Props {
	sx?: any
}

const MenuSections: FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const sectionSa = useStore(sectionSo)
	const draftSa = useStore(draftSo)

	// HOOKs
	const history = useHistory()
	const cssAnim = useMemo(() => animContainer(sectionSa.animState), [sectionSa.animState])
	const cssAnimSel = useMemo(() => animSelection(sectionSa.selected), [sectionSa.selected])

	// HANDLERs
	const handleClick = (item: MenuItem) => {
		const url = getUrlByPageId(item.id)
		if (!url) return
		history.push(url)
		if (deviceSo.state.type == DEVICE_TYPE.MOBILE) drawerSo.setIsOpen(false)
	}

	// RENDER
	const cnContainer = [cssContainer, cssAnim, sx]
	const cnSelector = [cssSelector, cssAnimSel]
	//const getInEdit = (item:MenuItem) => draftSo.isEditable() && [PAGE_ID.FARM_PLANNER, PAGE_ID.FARM_TASKS, PAGE_ID.FARM_CYCLES].includes(item.id)
	const isDisabled = (item: MenuItem) => item.onlyNats == true && natsSo.state.status != NATS_STATUS.ONLINE

	return <Box sx={cnContainer}>
		<Box sx={cnSelector} />
		<Box sx={cssList}>
			{sectionSa.items.map((item, index) => (
				<SectionItem key={item.id.toString()}
					item={item}
					// tooltip={getInEdit(item) ? "IN EDIT" : null}
					// highlight={getInEdit(item)}
					select={sectionSa.selected == index}
					onClick={() => handleClick(item)}
					disabled={isDisabled(item)}
				/>
			))}
		</Box>

	</Box>
}

export default MenuSections

const cssContainer = {
	position: "relative",
	transition: theme.transitions.create(['opacity', 'transform']),
	transform: "translate(0px, 0px)",
	opacity: "1",
}

const animContainer = (state: string | number) => ({
	normal: {
	},
	hide: {
		opacity: "0",
		transform: "translate(0px, -25px)",
	},
	show: {
	}
}[state])

const cssList = {
	position: "relative",
	display: "flex",
	flexDirection: "column",
}

const cssSelector = {
	height: theme.app.menu.section.height,
	width: theme.app.menu.width - (5 * 2),
	left: 5,
	borderRadius: "5px",
	backgroundColor: theme.palette.text.primary,
	position: "absolute",

	transition: theme.transitions.create(['top']),
}
const animSelection = (sel: number) => ({
	top: sel * theme.app.menu.section.height,
})