
export interface SharedServices {
    host: {
        url: string
    }
    nats: Nats
    cloudNats?: Nats
    components: Component[]
}

export interface Nats {
    host?: string
    port?: number
    username?: string // skip verification if null or empty
    password?: string
    enableTls?: boolean
    responseTimeout?: number
    subscribe: string
    publish: string
}

export interface Component {
    code?: string
    name?: string
    type: COMPONENT_TYPE
    disabled?: boolean
    hidden?: boolean
    subjects?: string[]
    options?: OptionsBase
}

export interface OptionsBase {
    grafana?: GrafanaOption[]
}

type GrafanaOption = {
    code: string,
    title?: string,
    url: string
}

export interface GrowUnitComponent extends Component {
    options: {
        code: string
    } & OptionsBase
}

export interface HVACComponent extends Component {
    options?: {
        number: number
    } & OptionsBase
}

export interface PowersComponent extends Component {
}

export interface GerminatorComponent extends Component {
}

export enum COMPONENT_TYPE {
    GERMINATOR = "germinator",
    GROWUNIT = "grow-unit",
    HVAC = "hvac",
    POWERS = "powers"
}
