import { Menu, MenuProps, MenuItem as MUIMenuItem } from "@mui/material";
import { useStore } from "@priolo/jon";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import farmSo from "stores/farm";
import growUnitSo from "stores/growUnit";
import { getGrowUnitName } from "stores/growUnit/utils";
import { MenuItem } from "stores/mainMenu/utils";
import { getUrlFromFarmPage, getUrlFromGroUnitPage } from "stores/route/utils/pagesId";
import { PAGE_ID } from "stores/route/utils/types";



interface Props extends MenuProps {
	item: MenuItem,
}

const ParentMenu: FunctionComponent<Props> = ({
	item,
	...props
}) => {

	// STORE
	const farmSa = useStore(farmSo)
	const growUnitSa = useStore(growUnitSo)

	// HOOKs
	const { t } = useTranslation()
	const history = useHistory()

	// ricava i dati da visualizzare
	const menuItems = useMemo(() => {
		let items: Item[] = []
		if (item.id == PAGE_ID.FARMS) {
			farmSo.fetchAllIfVoid()
			items = farmSa.all?.map<Item>(farm => ({
				label: farm.name ?? "--",
				isSelect: farm.id == farmSa.select?.id,
				onClick: () => {
					const url = getUrlFromFarmPage(farm.id)
					if (url) history.push(url)
				}
			})) ?? []
		}
		if (item.id == PAGE_ID.FARM_GROWUNITS) {
			items = farmSa.select?.growUnit?.map<Item>(growUnit => ({
				label: getGrowUnitName(growUnit) ?? "--",
				isSelect: growUnit.id == growUnitSa.select?.id,
				onClick: () => {
					const url = getUrlFromGroUnitPage(growUnit.id)
					if (url) history.push(url)
				}
			})) ?? []
		}
		return items.sort((i1, i2) => i1.label.localeCompare(i2.label))
	}, [farmSa, growUnitSa, item.id])

	// HANDLERS

	const handleClickItemMenu = (item: any) => {
		item.onClick()
		props.onClose(null, "backdropClick");
	}

	// RENDER
	if ( !(menuItems?.length > 0) ) return null
	return <>

		<Menu {...props}>
			{menuItems.map((menuItem, i) => (
				<MUIMenuItem key={i}
					onClick={() => handleClickItemMenu(menuItem)}
					selected={menuItem.isSelect}
				>{menuItem.label}</MUIMenuItem>
			))}
		</Menu>
	</>
}

export default ParentMenu

type Item = {
	label?: string,
	isSelect?: boolean,
	onClick?: () => void,
}