import { Color, Uuid } from "./global"
import { GROW_UNIT_TYPOLOGY } from "./GrowUnitEnum"


/**
 * Livello di severity dell'allarme
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-259/ALARMS#severity
 */
export enum ALARM_SEVERITY {
	CRITICAL = 1,
	HIGH = 2,
	MODERATE = 3,
	LOW = 4,
	INFORMATION = 5,
}
/**
 * Il tipo della struttura che definisce la severity di un allarme
 * i valori sono settati in ALARM_SEVERITY
 */
export interface AlarmSeverity {
	id: ALARM_SEVERITY,
	label: string
	color: Color
}
/**
 * Dati riguardo le singole SEVERITY
 */
export const AlarmSeverityData: AlarmSeverity[] = [
	{ id: ALARM_SEVERITY.CRITICAL, label: "critical", color: "#ed3833" },
	{ id: ALARM_SEVERITY.HIGH, label: "high", color: "#f28b43" },
	{ id: ALARM_SEVERITY.MODERATE, label: "moderate", color: "#f3c846" },
	{ id: ALARM_SEVERITY.LOW, label: "low", color: "#71cf36" },
	{ id: ALARM_SEVERITY.INFORMATION, label: "information", color: "#3a8222" },
]
export function getSeverityData(severity:ALARM_SEVERITY) {
	return AlarmSeverityData.find(as => as.id == severity)
}


/**
 * I team di competenze per la gestione degli allarmi
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-259/ALARMS#team
 */
export enum ALARM_TEAM {
	TECHNICAL_MANAGER = "technical_manager",
	ENGINEERS = "engineers",
	AGRO_GUYS = "agro_guys",
	ICT_GUYS = "ict_guys",
}


export interface AlarmDefault {
	description?: string
	wikiUrl?: string
	severity?: number
	teams?: ALARM_TEAM[]
	taskTemplateGroupUuid?: Uuid,
}

export interface AlarmOverride extends AlarmDefault {
	typologiesOverrided?: GROW_UNIT_TYPOLOGY[]
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27835
 */
export interface Alarm {
	alarmCode?: string
	newAlarmCode?: string
	management?: {
		default?: AlarmDefault
		typologyOverrides?: AlarmOverride[]
	}
}

export interface AlarmDefault {
	description?: string
	wikiUrl?: string
	severity?: number
	teams?: ALARM_TEAM[]
	task_template_group_uuid?: Uuid,
}

export interface AlarmOverride extends AlarmDefault {
	typologiesOverrided?: GROW_UNIT_TYPOLOGY[]
}

