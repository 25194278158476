import { createStore, resetAll, StoreCore, validateAll } from "@priolo/jon";
import metaApi from "api/metadata";
import i18n from "i18next";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";
import valueSo from "stores/values";
import { Metadata } from "types/Metadata";
import { Values } from "types/Value";
import { clone } from "utils/func";
import { formatFromMeta, formatToMeta, MetaTypeFormat } from "./utils";



// const tableByName = {}
const tableByAlias: { [alias: string]: Metadata } = {}

const setup = {

	state: {
		/** i METADATA selezionati*/
		metadata: <Metadata[]>null,
		lastKey: <string>null,
		metadataFarms: <{ [key: string]: Metadata[] }>{},
		/** METADATA selezionato in DIALOG */
		metaInEdit: <Metadata>null,
		format: <MetaTypeFormat>{},
		/** indica se un DETAIL diun METADATA è aperto o no */
		isMetaOpen: false,
		/** un array di GROUPs presenti nei METADATA */
		groups: <string[]>[],
		/** un array dei gruppi di "services code" presenti nei metadata */
		services: <string[]>[],
	},

	getters: {
		/** restituisce un METADATA tramite il suo name */
		getMetadata(
			{ serviceCode, name }: { serviceCode?: string, name: string },
			store?: MetadataStore
		) {
			if (!serviceCode) serviceCode = valueSo.state.serviceCodeSel
			if (!store.state.metadata || !serviceCode || !name) return null
			return store.state.metadata.find(meta => meta.name == name && meta.serviceCode == serviceCode)
			// const index = tableByName[`${serviceCode} ${name}`]
			// return store.state.metadata[index]
		},
		/** restituisce un METADATA tramite il suo name usando l'hash */
		getMetadataByAlias(
			{ serviceCode, alias }: { serviceCode?: string, alias: string },
			store?: MetadataStore
		) {
			if (!serviceCode) serviceCode = valueSo.state.serviceCodeSel
			if (!store.state.metadata || !serviceCode || !alias) return null
			const meta = tableByAlias[`${serviceCode.toUpperCase()} ${alias.toUpperCase()}`]
			return meta
			//return store.state.metadata.find(meta => meta.alias == alias && meta.serviceCode == serviceCode)
			//const index = tableByAlias[`${serviceCode} ${alias}`]
			//return store.state.metadata[index]
		}
	},

	actions: {
		/** recupera dal BE tutti i METADATA */
		async fetch(url?: string, store?: MetadataStore) {
			let metadata: Metadata[] = null
			if (import.meta.env.VITE_TARGET == "plc") {
				metadata = (await metaApi.index())?.data
			} else {
				if (!url) url = store.state.lastKey
				metadata = (await metaApi.index(url))?.data
				if (!metadata) return
				store.state.lastKey = url
			}
			store.selectMetadata(metadata)
			return metadata
		},
		/** carico i METADATA se non sono stati gia' caricati */
		async fetchIfVoid(url?: string, store?: MetadataStore) {
			if (import.meta.env.VITE_TARGET == "plc") {
				if (store.state.metadata?.length > 0) return store.state.metadata
				return store.fetch()
			}
			//if (!url) return
			let metadata = store.state.metadataFarms[url]
			if (!metadata) {
				metadata = await store.fetch(url)
				if ( !!url ) store.state.metadataFarms[url] = metadata
			} else {
				store.selectMetadata(metadata)
			}
			return metadata
		},
		/** consente di impostare tutte cose da una selezione di metadata per una specifica FARM (url) */
		selectMetadata(metadata: Metadata[], store?: MetadataStore) {
			// GROUPS e SERVICES
			const groups: string[] = []
			const services: string[] = []
			for (let index = 0; index < metadata.length; index++) {
				const meta = metadata[index]
				if (meta.group && meta.group.length > 0 && !groups.includes(meta.group)) groups.push(meta.group)
				if (meta.serviceCode && meta.serviceCode.length > 0 && !services.includes(meta.serviceCode)) services.push(meta.serviceCode)
				if (!!meta.alias && !!meta.serviceCode) {
					// 	tableByName[`${meta.serviceCode} ${meta.name}`] = index
					tableByAlias[`${meta.serviceCode.toUpperCase()} ${meta.alias.toUpperCase()}`] = meta
				}
			}
			store.setGroups(groups)
			store.setServices(services)
			store.setMetadata(metadata)
		},
		/** visualizza il dettaglio di un META */
		openMeta(meta: Metadata, store?: MetadataStore) {
			const metaClone = clone(meta)
			store.setMetaInEdit(metaClone)
			store.setFormat(formatFromMeta(metaClone))
			store.setIsMetaOpen(true)
			resetAll()
		},
		async close(save: boolean, store?: MetadataStore) {
			if (save) {
				if (validateAll().length > 0) return
				store.state.metaInEdit.format = formatToMeta(store.state.format)
				delete store.state.metaInEdit._tmp
				await metaApi.save(store.state.metaInEdit)
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.INFO, modal: false,
					text: i18n.t(`snackbar.default.save`)
				})
				await store.fetch()
			}
			store.setIsMetaOpen(false)
		},
		async remove(meta: Metadata, store?: MetadataStore) {
			if (!meta) return
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("snackbar.default.delete"),
			})
			if (res == false) return
			await metaApi.remove(meta)
			await store.fetch()
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t(`snackbar.default.modify`)
			})
		},
		update(
			{ serviceCode, values }: { serviceCode: string, values: Values },
			store?: MetadataStore
		) {
			if (!store.state.metadata) return null
			const newMetadata: Metadata[] = []
			Object.entries(values).forEach(([name, value]) => {
				const meta = store.getMetadata({ serviceCode, name })
				if (meta) return
				newMetadata.push({ serviceCode, name, _tmp: true })
			})
			if (newMetadata.length == 0) return
			store.setMetadata([...store.state.metadata, ...newMetadata])
		},
	},

	mutators: {
		setMetadata: (metadata: Metadata[], store?: MetadataStore) => ({ metadata }),
		setMetaInEdit: (metaInEdit: Metadata, store?: MetadataStore) => ({ metaInEdit }),
		setIsMetaOpen: (isMetaOpen: boolean, store?: MetadataStore) => ({ isMetaOpen }),
		setFormat: (format: MetaTypeFormat, store?: MetadataStore) => ({ format }),
		setGroups: (groups: string[], store?: MetadataStore) => ({ groups }),
		setServices: (services: string[], store?: MetadataStore) => ({ services }),
	},
}



export type MetadataActions = typeof setup.actions
export type MetadataMutators = typeof setup.mutators
export type MetadataState = typeof setup.state
export type MetadataGetters = typeof setup.getters
export interface MetadataStore extends StoreCore<MetadataState>, MetadataGetters, MetadataActions, MetadataMutators {
	state: MetadataState
}
const metaSo = createStore(setup) as MetadataStore
export default metaSo

