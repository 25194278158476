import { AccessTime, HourglassEmpty } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";
import VoidIcon from "images/void";
import { FunctionComponent } from "react";



export enum ICON_TYPE {
	WAIT,
	VOID,
	LOADING,
}

interface Props {
	title?: string
	subtitle?: string
	iconType?: ICON_TYPE
	sx?: SxProps
}

/**
 * PLACEHOLDER for void!
 */
const PlaceholderCmp: FunctionComponent<Props> = ({
	title = "THE LIST IS EMPTY",
	subtitle = "Try check if there are any filters",
	iconType = ICON_TYPE.WAIT,
	sx,
}) => {
	// HOOKs

	// HANDLER

	// RENDER
	const icon = {
		[ICON_TYPE.WAIT]: <AccessTime sx={cssIcon} />,
		[ICON_TYPE.VOID]: <VoidIcon style={cssIcon} />,
		[ICON_TYPE.LOADING]: <HourglassEmpty sx={cssIcon} />,
	}[iconType]

	return (
		<Box sx={[cssRoot as any, sx]}>
			<Box sx={cssContainer}>
				<Box sx={cssIconContainer}>
					{icon}
				</Box>
				<Box sx={cssText}>
					<Box sx={cssTitle}>{title}</Box>
					{subtitle && (
						<Box sx={cssSubTitle}>{subtitle}</Box>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default PlaceholderCmp


const cssRoot: SxProps = {
	display: 'flex',
	justifyContent: 'center',
	//height: '100%',
	alignItems: 'center',
	mt: "20%"
}

const cssContainer: SxProps = {
	display: 'flex',
	gap: "8px",
}

const cssIconContainer: SxProps = {
	display: 'flex',
	justifyContent: "center",
	alignItems: "center",
}

const cssIcon: SxProps = {
	width: '60px',
	height: '60px',
	padding: '5px'
}

const cssText: SxProps = {
	display: "flex",
	flexDirection: "column",
}

const cssTitle: SxProps = {
	display: "flex",
	fontSize: '20px',
	fontWeight: '900'
}
const cssSubTitle: SxProps = {
	display: "flex",
	fontSize: '16px',
	fontWeight: '500',
	opacity: '0.7'
}
