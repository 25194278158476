import { Box, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import FormParagraph from "components/controls/form/FormParagraph";
import Paragraph from "components/controls/form/Paragraph";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import dialogSo from "stores/cycle/editDialog";
import { getRef } from "utils/humanize";



const CycleCropLotsTab: FunctionComponent = () => {

    // STORE
    const dialogSa = useStore(dialogSo)

    // HOOKs
    const { t } = useTranslation()

    // RENDER
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {dialogSa.cycle?.cropLots?.map(cropLot =>

                <FormParagraph key={cropLot.id} title={cropLot.cropName}>
                    <Paragraph title={t("pag.cycle.edit.ref")}>
                        <Typography>{getRef(cropLot.uuid)}</Typography>
                    </Paragraph>
                    <Paragraph title={t("pag.cycle.edit.lot-code")}>
                        <Typography>{cropLot.lotCode}</Typography>
                    </Paragraph>
                    <Paragraph title={t("pag.cycle.edit.crop-lots.trolleys-to-seed")}>
                        <Typography>{cropLot.trolleysToSeed}</Typography>
                    </Paragraph>
                    <Paragraph title={t("pag.cycle.edit.crop-lots.trolleys-to-harvest")}>
                        <Typography>{cropLot.trolleysToHarvest}</Typography>
                    </Paragraph>
                    <Paragraph title={t("pag.cycle.edit.yield")}>
                        <Typography>{cropLot.yield ?? "--"}</Typography>
                    </Paragraph>
                    <Paragraph title="Units yield">
                        <Typography>{cropLot.unitsYield ?? "--"}</Typography>
                    </Paragraph>
                    <Paragraph title={t("pag.cycle.edit.planned-yield")} isLast>
                        <Typography>{cropLot.plannedYield?.toFixed(1) ?? "--"}</Typography>
                    </Paragraph>
                </FormParagraph>

            )}
        </Box>
    )
}

export default CycleCropLotsTab
