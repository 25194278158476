import { getLetters } from "utils/humanize"
import { isFarmLab } from "./index"
import { GrowUnit, GrowUnitLayer, PlanLayer } from "types/GrowUnit"



/** 
 * Restituisce i GrowUnitLayer "cambati" con il PlanLayer siderati per una GROW-UNIT 
 */
export function getLayersDesired(growUnit: GrowUnit): GrowUnitLayer[] {
	if (!growUnit) return []
	const propLayers = growUnit.desiredLayersState?.layers ?? growUnit.layers
	const layers = propLayers?.map(({ disabled, phase, layerNumber }) => {
		const layer = getLayerByNumber(growUnit, layerNumber)
		return {
			...layer,
			disabled,
			phase,
		} as GrowUnitLayer
	}) ?? []
	return layers
}

/**
 * restituisce il GrowUnitLayer di una GROWUNIT tramite il suo numero
 */
export function getLayerByNumber(growUnit: GrowUnit, number: number): GrowUnitLayer {
	if (!growUnit || !number) return null
	return growUnit.layers.find(layer => layer.layerNumber == number)
}

/**
 * restituisce il GrowUnitLayer di una GROWUNIT tramite il suo numero
 * col & row start index = 1
 */
export function getLayerByRowCol(growUnit: GrowUnit, col: number, row: number): GrowUnitLayer {
	if (!growUnit || !col || !row) return null
	return growUnit.layers.find(layer => layer.column == col && layer.row == row)
}

/** 
 * Restituisce il PlanLayer di una GROW-UNIT tramite il suo "number" 
 */
export function getLayerDesiredByNumber(growUnit: GrowUnit, number: number): PlanLayer {
	return growUnit.desiredLayersState?.layers?.find(layer => layer.layerNumber == number) ?? null
}

/** 
 * Restituisce il PlanLayer di una GROW-UNIT tramite il suo "number" 
 */
export function getLayerDesiredById(growUnit: GrowUnit, id: number): PlanLayer {
	return growUnit.desiredLayersState?.layers?.find(layer => layer.layerId == id) ?? null
}

/**
 * Estrapolo i GrowUnitLayer visibili di una specifica colonna di una GROW-UNIT
 * @param column start-index: 1
 */
export function getLayersOfColumn(growUnit: GrowUnit, column: number): GrowUnitLayer[] {
	if (!growUnit) return []
	return growUnit.layers
		.filter(layer => layer.column == column)
		.sort((a, b) => a.row - b.row)
}

/** 
 * Restituisce una copia di TUTTI i GrowUnitLayer di un array di GROW-UNITs
 */
export function getLayersFromGrowUnits(growUnits: GrowUnit[]): GrowUnitLayer[] {
	return growUnits.map(gu => getLayersDesired(gu)).flat()
}

/**
 * Restituisce il nome del layer calcolato sui dati della growunit
 * per esempio: "ABC1"
 */
export function getGrowUnitLayerName(growUnit: GrowUnit, layer: Layer): string {
	if (!layer || !growUnit) return "--"
	if (isFarmLab(growUnit)) {
		const num = layer.layerNumber
		return num > 1 ? `S${num}` : "M"
	} else {
		const step = growUnit.connectedLayers
		return `${getLetters(((layer.column - 1) * step), step).join("-")} ${layer.row}`
	}
}
type Layer = {
	layerNumber?: number,
	column?:number,
	row?:number,
}

/**
 * restituisce il nome di un layer in base alla GROW-UNIT
 * restituisce solo una lettera. index indica a quale lettera del gruppo di colonna fa riferimento
 * per esempio se il nome di un LAYER è: "ABC2"
 * index = 1 restituisce: "B2"
 */
export function getLayerIndexName(growUnit: GrowUnit, layer: Layer, index: number) {
	if (!layer || !growUnit) return "--"
	if (isFarmLab(growUnit)) {
		const num = layer.layerNumber
		return num > 1 ? `S${num}` : "M"
	} else {
		const step = growUnit.connectedLayers
		return `${getLetters(((layer.column - 1) * step) + index)}${layer.row}`
	}
}
