import { FunctionComponent, useMemo } from "react";

import { Box, Button } from "@mui/material";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Modal, { MODAL_ICONS } from "components/controls/Modal";
import DatePicker from "components/controls/fields/DatePicker";
import Paragraph from 'components/controls/form/Paragraph';

import SelectorCrop from "components/controls/select/SelectorCrop";
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from "react-i18next";

import { useStore } from "@priolo/jon";
import SelectorGrowUnit from "components/controls/select/SelectorGrowUnit";
import React from "react";
import cropSo from "stores/library/crop";
import cycleSo from "stores/cycle";
import dialogSo from "stores/cycle/newDialog";
import { getLayers, getLayersAllError, getLayersRatioError } from "stores/cycle/utils/layer";
import { cropInOverlap } from "stores/cycle/utils/overlap";
import farmSo from "stores/farm";
import growUnitSo from "stores/growUnit";
import { isBatchDesired } from "stores/growUnit/utils";
import theme from "styles/theme";
import { Crop } from "types/Crop";
import { GrowUnit } from "types/GrowUnit";
import CycleBatchLayersShow from "./CycleBatchLayersShow";
import CycleLayersSelector from "./CycleLayersSelector";



const CycleNewDialog: FunctionComponent = () => {

	// STORE
	const dialogSa = useStore(dialogSo)

	// HOOKs
	const { t } = useTranslation()
	const layerOvelap = useMemo(() => dialogSo.getLayersInOverlap(), [dialogSa.seedingDate, dialogSa.cropId, dialogSa.growUnitId, dialogSa.layersSelected])

	// HANDLERS
	const handleChangeDate = (date: Dayjs) => {
		dialogSo.setSeedingDate(date.valueOf())
	}
	const handleCropChange = (crop: Crop) => {
		dialogSo.setLayersSelected({})
		dialogSo.setCropId(crop?.id ?? null)
	}
	const handleGrowUnitChange = (growUnit: GrowUnit) => {
		dialogSo.setLayersSelected({})
		dialogSo.setGrowUnitId(growUnit?.id ?? null)
	}

	const handleDialogClose = () => dialogSo.close(false)
	const handleClickSave = () => dialogSo.close(true)

	// RENDER 
	if (!farmSo.state.select) return null
	const farmId = farmSo.state.select.id
	const growUnit = growUnitSo.getByID(dialogSa.growUnitId)
	const crop = cropSo.getById(dialogSa.cropId)
	const isBatch = isBatchDesired(growUnit)
	const cnDay = (day: dayjs.Dayjs/*, isInCurrentMonth*/) => {
		if (day.isBefore(dayjs()) /*|| !isInCurrentMonth*/) return []
		const layerOverlap = cropInOverlap(cycleSo.state.all, growUnit, crop, day.valueOf(), dialogSa.layersSelected)
		return layerOverlap?.length > 0 && cssDayError
	}
	const cropError = !dialogSa.cropId
	const layerOverlap = cropInOverlap(cycleSo.state.all, growUnit, crop, dialogSa.seedingDate, dialogSa.layersSelected)
	const overlapError = layerOverlap?.length > 0
	const rearrangmentError = getLayersRatioError(crop, dialogSa.layersSelected)
	// in batch va sempre bene
	const layerAllError = isBatch || getLayersAllError(crop, dialogSa.layersSelected)
	const layerNoError = getLayers(growUnit, crop)?.length == 0
	const isPast = dayjs(dialogSa.seedingDate).isBefore(dayjs(), "day")
	const canSave = cropError || rearrangmentError || layerAllError || layerNoError || isPast || overlapError

	return (
		<Modal
			maxWidth="md"
			title={t("pag.cycle.new.title")}
			isOpen={dialogSa.isOpen}
			onClose={handleDialogClose}

			chips={[
				//{ icon: MODAL_ICONS.FARM, label: growUnit?.name },
				{ icon: MODAL_ICONS.WARN, label: cropError ? t("pag.cycle.new.chip.select-crop") : null, tooltip: t("pag.cycle.new.chip.select-crop-tooltip") },
				{ icon: MODAL_ICONS.WARN, label: rearrangmentError ? t("pag.cycle.new.chip.rearrangment") : null, tooltip: t("pag.cycle.new.chip.rearrangment-tooltip") },
				{ icon: MODAL_ICONS.WARN, label: layerAllError ? t("pag.cycle.new.chip.all-phases") : null, tooltip: t("pag.cycle.new.chip.all-phases-tooltip") },
				{ icon: MODAL_ICONS.WARN, label: overlapError ? t("pag.cycle.new.chip.overlap") : null, tooltip: t("pag.cycle.new.chip.overlap-tooltip") },
				{ icon: MODAL_ICONS.WARN, label: layerNoError ? t("pag.cycle.new.chip.nolayer") : null, tooltip: t("pag.cycle.new.chip.nolayer-tooltip") },
				{ icon: MODAL_ICONS.WARN, label: isPast ? t("pag.cycle.new.chip.past") : null, tooltip: t("pag.cycle.new.chip.past-tooltip") },
			]}

			actionsRender={<>
				<div style={{ flex: "1 1 auto" }} />
				<Button onClick={handleDialogClose}>
					{t("pag.cycle.new.btt-cancel")}
				</Button>
				<Button onClick={handleClickSave}
					color="secondary"
					disabled={canSave}
				>{t("pag.cycle.new.btt-create")}</Button>
			</>}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }} >

				{/* SEEDING DATE
        		NEW OR IN PLANNING YOU CAN SET THE SEEDING DATE */}
				<Paragraph title={t("pag.cycle.new.prg-date")}>
					<DatePicker
						disablePast
						value={dayjs(dialogSa.seedingDate)}
						onChange={handleChangeDate}
						renderDay={(day: dayjs.Dayjs, selectedDay, pickersDayProps) => {
							return <PickersDay {...pickersDayProps} sx={cnDay(day)} />
							//return <div className={cnDay(day, isInCurrentMonth)}>{dayComponent}</div>
						}}
					/>
				</Paragraph>

				{/* SELECTOR GROW UNIT */}
				<Paragraph title={t("pag.cycle.new.growunit")}>
					<SelectorGrowUnit sx={{ flex: 1 }}
						growUnitId={dialogSa.growUnitId}
						placeholder={t("selector.growunit.title")}
						onChange={handleGrowUnitChange}
					/>
				</Paragraph>

				{/* SELECT CROP */}
				<Paragraph title={t("pag.cycle.new.crop")}>
					<SelectorCrop sx={{ flex: 1 }}
						farmId={farmId}
						cropId={dialogSa.cropId}
						placeholder={t("selector.crop")}
						onChange={handleCropChange}
					/>
				</Paragraph>

				{/* SHOW OR EDIT LAYERS */}
				{isBatch ? (
					<CycleBatchLayersShow
						growUnit={growUnit}
						crop={crop}
					/>
				) : (
					<CycleLayersSelector
						growUnit={growUnit}
						crop={crop}
						layerOvelap={layerOvelap}
					/>
				)}

			</Box>
		</Modal>
	)
}

export default CycleNewDialog


const cssDayError = {
	border: `2px solid`,
	borderColor: theme.palette.error.main,
}
