import { Box } from "@mui/material";
import { useStore } from "@priolo/jon";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";
import layoutSo from "stores/layout";
import parentsSo from "stores/mainMenu/parents";
import sectionSo, { AnimSectionsState } from "stores/mainMenu/sections";
import { MenuItem } from "stores/mainMenu/utils";
import { getUrlByPageId } from "stores/route/utils/pagesId";
import ParentItem from "./ParentItem";
import SelectItem from "./SelectItem";



const TitleCmp: FunctionComponent = () => {

	// STORE
	const parentsSa = useStore(parentsSo)
	const sectionSa = useStore(sectionSo)
	const layoutSa = useStore(layoutSo)

	// HOOKs
	const history = useHistory()
	const select = sectionSa.items[sectionSa.selected]
	const isRoot = parentsSa.items.length == 0

	// HANDLERS
	function handleParentClick(item: MenuItem) {
		const url = getUrlByPageId(item.id)
		if (url) history.push(url)
	}

	// RENDER
	return <Box sx={{ display: "flex", alignItems: "center" }}>

		{parentsSa.items.map(item => (
			<ParentItem key={item.id}
				item={item}
				onClickIcon={() => handleParentClick(item)}
			/>
		))}

		{sectionSa.animState != AnimSectionsState.HIDE && (
			<SelectItem item={select} isRoot={isRoot} />
		)}

	</Box>
}

export default TitleCmp
