
import { AutoStories as RecipeIcon, Schedule as SchemaIcon, Warning as WarningIcon } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Breakpoint, Button, Dialog, IconButton, IconProps, Typography } from '@mui/material';
import GrowUnitIcon from "images/menu/growunit";
import React, { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Chip from "./select/Chip";



export interface ModalProps extends HTMLAttributes<HTMLElement> {
	icon?: ReactNode
	title?: any
	isOpen?: boolean

	onClose?: (confirm?: boolean) => void
	onEnter?: () => void

	disableSubmit?: boolean
	/** sono le CHIPS che apaiono sotto al TITLE e indicano warning della compilazione della DIALOG */
	chips?: WarnChip[]
	/** cioe' che viene renderizzato sul FOOTER */
	actionsRender?: ReactNode

	/** string "xs":0px "sm":600px "md":960px "lg":1280px "xl":1920px */
	maxWidth?: Breakpoint | false
	fullWidth?: boolean
	sx?: any

	children?: ReactNode
}

const Modal: FunctionComponent<ModalProps> = ({
	icon,
	title,
	children,
	isOpen,
	onClose,
	onEnter,
	disableSubmit,
	chips = [],
	actionsRender,

	maxWidth = "sm",
	fullWidth = true,
	sx,
	...props
}) => {

	// HOOKS
	const { t } = useTranslation()

	// HANDLERS
	const handleKeyDown = (e) => {
		if (!onEnter) return
		if (e.code == "Enter") {
			e.preventDefault()
			onEnter()
		}
	}

	// RENDER
	return (
		<Dialog scroll="body"
			open={isOpen}
			onClose={_ => onClose()}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			onKeyDown={handleKeyDown}
			sx={sx}
			{...props}
		>

			{/* TITLE */}
			<Box sx={{ display: "flex", flexDirection: "column" }}>

				{/* TITLE */}
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Box sx={{ display: "flex", marginRight: "10px" }}>
						{icon}
					</Box>

					<Typography variant="h5" sx={{ flex: "1" }} >
						{title}
					</Typography>

					<IconButton onClick={() => onClose()}>
						<CloseIcon />
					</IconButton>
				</Box>

				{/* CHIPS */}
				<ChipList chips={chips} />

			</Box>

			{/* BODY */}
			<Box sx={{ m: "5px 12px" }} >
				{children}
			</Box>

			{/* FOOTER */}
			<Box sx={{ display: "flex", mt: "20px" }}>
				{actionsRender ? actionsRender : <>
					<Box sx={{ flex: "1 1 auto" }} />
					<Button
						onClick={() => onClose(false)}
					>
						{t("dialog.default.modal.btt-cancel")}
					</Button>
					<Button
						color="secondary"
						disabled={disableSubmit}
						onClick={() => onClose(true)}
					>
						{t("dialog.default.modal.btt-ok")}
					</Button>
				</>}
			</Box>

		</Dialog>
	);
}

export default Modal


/** struttura dati delle informazioni che appaiono in alto sul DIALOG in forma di CHIPs */
export type WarnChip = {
	label?: string,
	icon?: MODAL_ICONS,
	tooltip?: string,
}


interface ChipListProps {
	chips?: WarnChip[]
}
const ChipList: FunctionComponent<ChipListProps> = ({
	chips
}) => {

	chips = chips?.filter(c => c?.label != null)
	const haveChip = chips != null && chips.length > 0
	if (!haveChip) return null

	return <Box sx={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
		{chips.map((c, i) => (
			<Chip key={i} noClear
				active={c.icon == "warn"}
				tooltip={c.tooltip}
				label={c.label}
				icon={<IconSlug slug={c.icon} style={{ width: "18px", height: "18px" }} />}
			/>
		))}
	</Box>
}

interface IconSlugProps extends IconProps {
	slug: string,
	width?: string,
	height?: string
}

export enum MODAL_ICONS {
	FARM = "farm",
	RECIPE = "recipe",
	SCHEMA = "schema",
	WARN = "warn",
}

const IconSlug: FunctionComponent<IconSlugProps> = ({
	slug,
	...props
}) => {
	const icons = {
		[MODAL_ICONS.FARM]: GrowUnitIcon,
		[MODAL_ICONS.RECIPE]: RecipeIcon,
		[MODAL_ICONS.SCHEMA]: SchemaIcon,
		[MODAL_ICONS.WARN]: WarningIcon,
	}
	//other.color = "primary"
	return icons[slug] != null && React.createElement(icons[slug], props)
}
