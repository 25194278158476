import { Timeline } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useStore } from "@priolo/jon";
import ButtonCmp from "components/controls/buttons/ButtonCmp";
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import dialogSo, { CYCLE_EDIT_TYPE } from "stores/cycle/editDialog";
import { getCycleStatus } from "stores/cycle/utils/cycle";
import { CYCLE_STATUS } from "stores/cycle/utils/enums";
import draftSo from "stores/draft";
import { SESSION_TYPE } from "stores/draft/utils";
import growUnitSo from "stores/growUnit";
import { getRef } from "utils/humanize";
import HarvestButton from "./HarvestButton";



/**
 * la zona di ACTIONS (cioe' la bottoniera che sta sotto alla dialog cycle)
 */
const CycleActions: FunctionComponent = () => {

    // STORE
    const dialogSa = useStore(dialogSo)
    const draftSa = useStore(draftSo)

    // HOOKS
    const { t } = useTranslation()

    // HANDLEs
    const handleClose = () => dialogSo.close(CYCLE_EDIT_TYPE.CLOSE)
    const handleSync = () => dialogSo.taskSync()
    const handleTimeSeries = () => {
        const cycle = dialogSa.cycle
        if (!cycle || !cycle.seedingDate) return
        const orgId = "1"
        const growUnit = growUnitSo.getByID(cycle.growUnitId)
        const cycleRef = getRef(cycle.cycleUuid)
        const from = dayjs(cycle.seedingDate).valueOf()
        const to = dayjs(cycle.harvestingDate ?? Date.now()).valueOf()
        window.open(`https://dashboards.zerofarms.it/d/txG7exXIk/cycle-dashboard?orgId=${orgId}&var-grow_unit_code=${growUnit.growUnitCode}&var-cycle_id=${cycleRef}&from=${from}&to=${to}`, "timeSeries")
    }

    // RENDER
    const cycleStatus = getCycleStatus(dialogSa.cycle)
    const canHarvest = cycleStatus == CYCLE_STATUS.RUNNING || cycleStatus == CYCLE_STATUS.HARVESTED
    // è possibile SYNC solo su PROD
    const session = draftSo.getSessionSelect()
    const disableSync = !!session && (session.type != SESSION_TYPE.PROD || dialogSa.syncDisabled)
    const tooltipMsg = disableSync ? t("pag.cycle.edit.no-only-read") : null

    return <>

        <Button
            startIcon={<Timeline />}
            onClick={handleTimeSeries}
        >TIME SERIES</Button>

        <Box sx={{ flex: 1 }} />

        <Button
            onClick={handleClose}
        >{t("pag.cycle.edit.btt-close")}</Button>

        {[
            canHarvest && <HarvestButton />,
            null,
            <ButtonCmp
                tooltip={tooltipMsg}
                color="secondary"
                onClick={handleSync}
                disabled={disableSync}
            >{t("pag.cycle.edit.btt-sync")}</ButtonCmp>
        ][dialogSa.tab] ?? null}
    </>
}


export default CycleActions