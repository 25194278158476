import { Notes } from "@mui/icons-material";
import { Box, Button, InputBase, InputBaseProps } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import Modal from "../Modal";
import WrapControl, { ICON_VARIANT } from "../base/WrapControl";
import TextField from "./TextField";



interface Props extends InputBaseProps {
	title?: string
	helperText?: string
	sx?: any
}

const TextAreaField: FunctionComponent<Props> = ({
	title,
	helperText,
	sx,
	...props
}) => {

	// HOOKS
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleOpen = () => {
		setIsOpen(true)
	}
	const handleClose = () => {
		setIsOpen(false)
	}


	// RENDER
	return (<>
		<WrapControl sx={sx}
			helperText={helperText}
			variantIcon={ICON_VARIANT.PAGE}
			onClick={handleOpen}
		>
			<InputBase fullWidth
				autoComplete='off'
				type="text"
				{...props}
			/>
		</WrapControl>

		<Modal maxWidth="md"
			title={title}
			icon={<Notes />}
			isOpen={isOpen}
			onClose={handleClose}
			actionsRender={<><Box sx={{flex: 1}} /><Button onClick={handleClose}>Close</Button></>}
		>
			<TextField
				{...props}
				multiline
				rows={20}
			/>
		</Modal>
	</>
	)
}

export default TextAreaField
