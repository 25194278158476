import { createStore, StoreCore } from "@priolo/jon";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";



export enum NATS_STATUS {
	UNKNOW = "unk",
	ONLINE = "on",
	RETRY = "rty",
	OFFLINE = "off",
}

let toIdTolerance: any = null
const timerTollerance = 3000

const setup = {

	state: {
		/**  */
		status: NATS_STATUS.UNKNOW,
	},

	getters: {
	},

	actions: {
		changeStatus: (status: NATS_STATUS, store?: NatsStore) => {
			const isOnline = status == NATS_STATUS.ONLINE || status == NATS_STATUS.UNKNOW
			const isOldOnline = store.state.status == NATS_STATUS.ONLINE || store.state.status == NATS_STATUS.UNKNOW

			if (isOnline != isOldOnline) {
				clearTimeout(toIdTolerance)
				toIdTolerance = null

				// if (status == NATS_STATUS.ONLINE) {
				// 	toIdTolerance = setTimeout(() => {
				// 		dialogSo.dialogOpen({
				// 			type: DIALOG_TYPE.INFO,
				// 			title: `YOU'RE BACK ONLINE.`,
				// 			text: `You can continue to use the panel.`,
				// 			labelCancel: null,
				// 		})
				// 	}, timerTollerance)
				// }

				// if (!isOnline) {
				// 	toIdTolerance = setTimeout(() => {
				// 		dialogSo.dialogOpen({
				// 			type: DIALOG_TYPE.ERROR,
				// 			title: `YOU ARE OFFLINE`,
				// 			text: `WARNING: The data displayed is not updated.`,
				// 			labelCancel: null,
				// 		})
				// 	}, timerTollerance)
				// }
			}

			store.setStatus(status)
		},
	},

	mutators: {
		setStatus: (status: NATS_STATUS) => ({ status })
	},
}

export type NatsState = typeof setup.state
export type NatsGetters = typeof setup.getters
export type NatsActions = typeof setup.actions
export type NatsMutators = typeof setup.mutators
export interface NatsStore extends StoreCore<NatsState>, NatsGetters, NatsActions, NatsMutators {
	state: NatsState
}
const natsSo = createStore(setup) as NatsStore
export default natsSo
