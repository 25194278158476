import { BugReport as BugIcon, ExitToApp as LogoutIcon, Face as FaceIcon, Tune as PreferencesIcon, Factory } from '@mui/icons-material';
import { Avatar, Box, BoxProps, Divider, IconButton, IconButtonProps, List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";
import React, { FunctionComponent, useRef } from 'react';
import packageJson from '../../../package.json';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useStore } from '@priolo/jon';
import { getUrlByPageId } from 'stores/route/utils/pagesId';
import { PAGE_ID } from 'stores/route/utils/types';
import userSo from 'stores/user';



const UserButton: FunctionComponent<IconButtonProps> = (props) => {

	// STORE
	const userSa = useStore(userSo)

	// HOOKs
	const { t } = useTranslation()
	const anchorRef = useRef()
	const history = useHistory()


	// HANDLEs
	const handleOpen = () => userSo.setMenuOpen(true)

	const handleClose = () => userSo.setMenuOpen(false)

	const handleLogout = () => userSo.logout()

	const handlePreferences = () => {
		history.push(getUrlByPageId(PAGE_ID.PREFERENCES))
		userSo.setMenuOpen(false)
	}


	// RENDER
	if (!userSo.isLogged()) return null

	return <>

		<IconButton
			onClick={handleOpen}
			ref={anchorRef}
			{...props}
		>
			<FaceIcon fontSize='medium' />
		</IconButton>

		<Popover
			open={userSa.menuOpen}
			anchorEl={anchorRef.current}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }} >

				<Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }} >
					<Typography color="secondary">{userSa.current.email}</Typography>
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						<Typography>{userSa.current.name}</Typography>
						{userSa.current.debugEnabled && <BugIcon />}
						{userSa.current.hasPlcAccess && <Factory />}
					</Box>
				</Box>

				<Divider />

				<Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
					<List>
						<ListItemButton onClick={handlePreferences}>
							<ListItemIcon><PreferencesIcon /></ListItemIcon>
							<ListItemText primary={t("dialog.user.preferences")} />
						</ListItemButton>
						<ListItemButton onClick={handleLogout}>
							<ListItemIcon><LogoutIcon /></ListItemIcon>
							<ListItemText primary={t("dialog.user.logout")} />
						</ListItemButton>
					</List>
					<Box sx={{ alignSelf: "end", margin: "0px 20px 10px 0px" }}>
						<Typography
							variant="caption"
						>{packageJson.version}</Typography>
					</Box>
				</Box>

			</Box>
		</Popover>

	</>
}

export default UserButton
